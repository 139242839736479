/* Footer.css */
.hover:hover {
  color: #0283AB;
  text-decoration: underline;
}

/* Ensure keyboard focus is clearly visible */
a:focus, button:focus {
  outline: 3px solid #ffffff;
  outline-offset: 2px;
}

footer li { 
  white-space: nowrap;
}

footer ul {
  white-space: wrap;
}

.sr-only {
  position: absolute; /* Remove the element from the document flow */
  width: 1px;         /* Minimal width */
  height: 1px;        /* Minimal height */
  padding: 0;         /* Remove any padding */
  margin: -1px;       /* Negative margin to ensure it's off-screen */
  overflow: hidden;   /* Hide any overflowing content */
  clip: rect(0, 0, 0, 0); /* Clip the element to a 0x0 rectangle */
  white-space: nowrap; /* Prevent text from wrapping */
  border: 0;         /* Remove any border */
}


/* Button-like styling for the phone number with improved contrast */
.call-to-action {
  background-color: #fff; /* Dark blue for sufficient contrast */
  color: #004080;
  padding: 8px 15px;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
}

/* .call-to-action:hover { */
  /* background-color: #003366; Darker shade on hover */
/* } */

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-section-container {
    margin-bottom: 20px;
  }
  footer ul { 
    flex-direction: column !important;
  }
}

/* Navigation Styling */
.main-navigation .nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.main-navigation .nav-item {
  padding: 0.5em 0;
}

@media (min-width: 768px) {
  .main-navigation .nav-list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
