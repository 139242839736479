.nav-link:hover {
    color: #0283AB;
}

.dropdown-item {
    border-bottom: 2px solid #f2f2f2;
    padding: 10px 0;
    color: #102942;
}

.no-bottom {
    border-bottom: none;
}

.dropdown-item:hover {
    background-color: #f2f2f2;
    text-decoration: underline;
}

.blue-border {
    border: 2px solid #0283AB;
}

.forefront {
    z-index: 999;
}

.desktop-menu {
    display: none;
}

@media (min-width: 768px) {
    .desktop-menu {
        display: flex;
    }

    .bm-burger-button {
        display: none;
    }
}