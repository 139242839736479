.App {
  text-align: center;
}

/* For headings and subtitles */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Cinzel Decorative', 'Georgia', 'Times New Roman', serif
  ;
}

/* For paragraphs and list items */
p, ul, ol, li {
  font-family: 'Montserrat', 'Arial', 'Helvetica', 'sans-serif';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
